import { createContext, useState } from 'react';

import { AnimatePresence } from 'framer-motion';
import type { AppProps } from 'next/app';
import { Josefin_Sans } from 'next/font/google';
import localFont from 'next/font/local';
import { useSearchParams } from 'next/navigation';
import Script from 'next/script';
import { appWithTranslation } from 'next-i18next';

import 'vanilla-cookieconsent/dist/cookieconsent.css';
import '../common/styles/global.css';

const modernist = localFont({
  src: [
    { path: '../assets/font/Sk-Modernist-Mono.otf', weight: '100' },
    { path: '../assets/font/Sk-Modernist-Regular.otf', weight: '400' },
    { path: '../assets/font/Sk-Modernist-Bold.otf', weight: '600' },
  ],
  variable: '--font-modernist',
});

const josefin = Josefin_Sans({ subsets: ['latin'] });

const GTM_ID = 'GTM-W2JJX9CC';

export const ConsentContext = createContext({
  enabled: false,
  handleConsent: (value: boolean) => {},
});

function App({ Component, pageProps }: AppProps) {
  const searchParams = useSearchParams();
  const [consentEnabled, setConsentEnabled] = useState(false);

  const handleConsent = (value: boolean) => {
    setConsentEnabled(value);
  };

  const gtmScriptEnabled =
    typeof window !== 'undefined' &&
    !window.location?.hostname?.includes('staging') &&
    searchParams.get('cookie-consent') !== 'false';

  return (
    <AnimatePresence mode="sync" initial={false}>
      <ConsentContext.Provider value={{ enabled: consentEnabled, handleConsent }}>
        <style jsx global>{`
          * {
            letter-spacing: -0.2px;
          }
          body {
            font-family: ${modernist.style.fontFamily}, sans-serif;
            font-size: 21px;
            line-height: 30px;
            letter-spacing: -0.2px;
          }
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            font-family: ${modernist.style.fontFamily}, sans-serif;
          }
          .josefin {
            font-family: ${josefin.style.fontFamily}, sans-serif;
          }
        `}</style>
        {gtmScriptEnabled && consentEnabled && (
          <Script id="gtm-script" type="module">
            {`
            (function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${GTM_ID}');
            `}
          </Script>
        )}

        <Component {...pageProps} />
      </ConsentContext.Provider>
    </AnimatePresence>
  );
}

export default appWithTranslation(App);
